import React, { useContext } from 'react';
import {
    Grid,
    Container,
    Card,
    CardContent,
    Typography, makeStyles,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Page from 'components/Page';
import PackageCountByDayStatistics from './PackageCountByDayStatistics';
import PackageCountByMonthStatistics from './PackageCountByMonthStatistics';
import PackageProfitByMonthStatistics from './PackageProfitByMonthStatistics';
import PackageByWeightStatistics from './PackageByWeightStatistics';
import TableFilter from 'components/TableFilter';
import {createUrlQuery, isAdmin} from 'helpers';
import AppContext from 'context/AppContext';
import ServiceProfitByMonthStatistics from "./ServiceProfitByMonthStatistics";
import Alert from "@material-ui/lab/Alert";
import {hasService} from "../../../helpers";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    yMargin: {
        margin: '15px 0',
    }
}));


export default function PackageCountStatistics({ ...rest }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [filter, setFilter] = React.useState({});
    const [params, setParams] = React.useState('');
    const { appValues } = useContext(AppContext);

    const setFilterValue = (name, value) => {
        let tmpFilter = { ...filter, [name]: value };
        setParams(createUrlQuery(tmpFilter));
        setFilter({ ...filter, [name]: value });
    }

    return (
        <Page
            title={t('navbar.package_count')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {t('navbar.package_count')}
                </Typography>
                <Grid container spacing={3} alignItems="center" >
                    <Grid item xs={12}>
                        <Card className="filterRow">
                            <CardContent>
                                <TableFilter
                                    filter={filter}
                                    enabled={{
                                        created_at: true,
                                        user: !appValues.user.roles.includes('ROLE_USER'),
                                        shipper: true,
                                    }}
                                    setFilterValue={setFilterValue}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <PackageCountByDayStatistics params={params} />
                    </Grid>
                    <Grid item xs={12}>
                        <PackageCountByMonthStatistics params={params} />
                    </Grid>
                    {
                        appValues.user.roles.includes('ROLE_ADMIN') && 
                        <Grid item xs={12}>
                            <PackageProfitByMonthStatistics params={params} />
                        </Grid>
                    }
                    {
                        appValues.user.roles.includes('ROLE_ADMIN') &&
                        <Grid item xs={12}>
                            <ServiceProfitByMonthStatistics params={params} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {
                            (isAdmin(appValues.user) || hasService(appValues.user) ) &&
                            <Alert severity="info" className={classes.yMargin}>
                                <strong>Upozornenie:</strong> Zobrazujú sa informácie iba o balíkoch z verzie <strong>Prémium</strong>.
                            </Alert>
                        }
                        <PackageByWeightStatistics params={params} />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}